export default {
    data() {
        return {
            yms: [],
            cfg: {},
            logs: [],
            cypts: [],
            jsexs: [],
            kj_index: 0,
            kjs: [],
            kj_info: {
                ss: 0,
                nums: []
            },
            timer: 0,
            banners: [],
            next_info: {},
            webs: [],
            scroll_top: 0
        }
    },
    created() {
        this._config(['app_name', 'app_name5', 'logo5',
            'ym5',
            'gg4',
            'ym41',
            'ym42',
            'ym43',
            'ym44',
        ]);
        this._logs();
        let width = window.innerWidth;
        width = Math.max(375, width);
        width = Math.min(420, width);
        document.documentElement.style.fontSize = (width / 375) + 'px';
        this._get_kjs(0);
        this._banners();
        window.addEventListener('scroll', this._scroll);
    },
    methods: {
        _scroll() {
            this.scroll_top = window.scrollY;
        },
        getRandomItemsUnique(array, count) {
            // 创建一个数组的副本，以避免改变原始数组
            let shuffled = array.slice();
            // 使用Fisher-Yates（也称为Knuth）洗牌算法
            for (let i = shuffled.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
            }
            // 返回前count个元素
            return shuffled.slice(0, count);
        },
        _get_ds_wei(is_d) {
            let d = [1, 3, 5, 7, 9];
            let s = [0, 2, 4, 6, 8];
            if (is_d) {
                return this.getRandomItemsUnique(d, 4);
            } else {
                return this.getRandomItemsUnique(s, 4);
            }
        },
        _get_ds4x(ds) {
            let arr_d = ['兔', '牛', '猪', '鸡', '羊', '蛇'];
            let arr_s = ['虎', '鼠', '猴', '龙', '马', '狗'];
            if (ds) {
                return this.getRandomItemsUnique(arr_d, 4);
            } else {
                return this.getRandomItemsUnique(arr_s, 4);
            }
        },
        _get_nan_nav(nn) {
            let nan = ['鼠', '牛', '虎', '龙', '马', '猴', '狗'];
            let nv = ['兔', '蛇', '羊', '鸡', '猪'];
            if (nn) {
                return this.getRandomItemsUnique(nan, 4);
            } else {
                return this.getRandomItemsUnique(nv, 4);
            }
        },
        _get_js_duan(nums) {
            let arr = [];
            arr.push(nums[0]);
            arr.push(nums[nums.length - 1]);
            arr.sort();
            return this._bu_num(arr[0]) + '-' + this._bu_num(arr[1] || 0);
        },
        _go_to_id(id) {
            // 获取ID为'test'的div元素
            var element = document.getElementById(id);
            // 滚动到该元素的位置
            if (element) {
                // 计算需要滚动的距离
                var scrollDistance = element.getBoundingClientRect().top + window.scrollY - 1; // 300px是头部的高度
                // 使用window.scrollTo方法滚动到计算后的位置
                window.scrollTo({
                    top: scrollDistance,
                    behavior: 'smooth' // 平滑滚动，如果需要的话
                });
            } else {
                console.warn('Element with ID "test" not found.');
            }
        },
        _arrs_has_same(array1, array2) {
            let arr = array1.filter(item => array2.indexOf(item) >= 0);
            if (arr.length) {
                return true;
            } else {
                return false;
            }
        },
        _get_nums_shus(nums) {
            let shus = [];
            nums.map(item => {
                shus.push(this._get_num_info(item).shu);
            });
            return shus;
        },
        _get_10s(num) {
            let numbers = [];
            for (let i = 1; i <= 49; i++) {
                if (i != num) {
                    numbers.push(i);
                }
            }
            numbers.sort(() => Math.random() - 0.5);
            return numbers.slice(0, 10);
        },
        _banners() {
            this.$http.post('banner5/list', {
                myorder: 'asc',
                limit: 100
            }).then(res => {
                if (res.code) {
                    this.banners = res.data.data;
                }
            })
        },
        _banner(index) {
            return this.banners[index]?.url;
        },
        _get_kjs(index) {
            this.kj_info = this.$options.data().kj_info;
            this.kj_index = index;
            if (index == 0) {
                this.kj_info.title = this.cfg.app_name || '新港彩';
                this.$http.get(`log/last`).then(res => {
                    if (res.code) {
                        this.next_info = res.data.next;
                        this.kj_info = res.data.last;
                        this.kj_info.qi = res.data.last.qi;
                        this.kj_info.nums = res.data.last.nums;
                        // this.$set(this.kj_info, 'ss', this._get_ss_hm('22点30分'));
                        this.$set(this.kj_info, 'ss', this._get_ss_hm('22点30分'));
                        clearInterval(this.timer);
                        this.timer = setInterval(() => {
                            this.kj_info.ss--;
                            // if (this.kj_info.ss < 0 && this.kj_info.ss > -60 && this.kj_index == 0) {
                            if (this.kj_info.ss < 0 && this.kj_info.ss > -1 && this.kj_index == 0) {
								
                                this._get_kjs(0);
                            }
                        }, 1000);
                    }
                });
            }
        },
        _logs() {
            this.$http.post('log/list', {
                is_all: 1,
                limit: 10,
                order: {
                    qi: 'desc'
                }
            }).then(res => {
                if (res.code) {
                    let logs = res.data.data;
                    this.logs = logs.map(item => {
                        return {
                            ...item,
                            dxiaos: this._get_ds4x(1),
                            sxiaos: this._get_ds4x(0),
                            dsx4d: this._get_ds_wei(1),
                            dsx4s: this._get_ds_wei(0),
                            nxiaos: this._get_nan_nav(1),
                            nvxiaos: this._get_nan_nav(0),
                        };
                    })
                }
            })
        },
        formatTime(seconds) {
            if (seconds < 0) {
                return '00:00:00';
            }
            var hours = Math.floor(seconds / 3600);
            var minutes = Math.floor((seconds % 3600) / 60);
            var remainingSeconds = seconds % 60;
            // 格式化为时分秒的字符串
            var formattedTime = this.pad(hours) + ":" + this.pad(minutes) + ":" + this.pad(remainingSeconds);
            return formattedTime;
        },
        pad(number) {
            return (number < 10) ? "0" + number : number;
        },
        extractHourAndMinute(timeString) {
            // 使用正则表达式匹配小时和分钟
            var match = timeString.match(/(\d{1,2})点(\d{1,2})分/);
            // 如果匹配成功，返回小时和分钟
            if (match) {
                var h = parseInt(match[1], 10);
                var m = parseInt(match[2], 10);
                console.log(h, m);
                return { h, m };
            }
            return { h: 0, m: 0 };
        },
        _get_ss_hm(time) {
            let { h, m } = this.extractHourAndMinute(time);
            // 获取当前时间
            var now = new Date();
            // 创建目标时间的 Date 对象
            var targetTime = new Date();
            targetTime.setHours(h, m, 0, 0);
            // 计算时间差（单位：毫秒）
            var timeDiff = targetTime - now;
            // 将毫秒转换为秒
            var ss = Math.floor(timeDiff / 1000);
            console.log('ss' + ss);
            // if(ss<0){
            // }
            if (ss <= -120) {
                return 24 * 3600 + ss;
            }
            // else if (ss <= 0) {
            //     return 0;
            // }
            return ss;
        },
    }
}