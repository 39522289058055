<template>
    <div id="app">
        <router-view :key="key"></router-view>
    </div>
</template>
<script>
export default {
    data() {
        return {

        };
    },
    computed: {
        key() {
            return this.$route.fullPath + new Date();
        },

    },

    methods: {

    },
};
</script>
<style lang="scss">
@import "css/common.scss";
@import "css/style.scss";



* {
    margin: 0;
    padding: 0;
    word-break: break-all;
    word-wrap: break-word;
}

html {
    background: url('~@/static/bg.jpg') top center no-repeat #bacdf0;
    background-size: cover;
    background-attachment: fixed;
}

body {
    width: 100%;
    max-width: 720px;
    min-height: 100vh;
    margin: 0 auto;
    line-height: 1.5;
    font-size: 15px;
    font-weight: bold;
    color: #000;
    font-family: '微软雅黑', Arial, Helvetica, sans-serif;
    background: #fff;
}
</style>